<template>
  <NavigationMenu>
    <NavigationMenuList>
      <NavigationMenuItem>
        <NavigationMenuTrigger class="justify-between sm:w-60 md:w-72 lg:w-96">
          <IconComponent name="PhCompass" class="mr-2 text-accent" />
          <span class="text-base">Quicklinks</span>
        </NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/shipments'"
                  :class="hasAccess(AccessKey.VIEW_SHIPMENTS)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent-emphasis hover:text-accent focus:bg-accent-emphasis focus:text-accent"
                >
                  <div class="flex items-center gap-2 font-medium leading-none">
                    <IconComponent name="PhPackage" class="text-accent" />
                    Shipments
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted">
                    Overview of all shipments
                  </p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/shipments/create'"
                  :class="hasAccess(AccessKey.CREATE_SHIPMENT)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent-emphasis hover:text-accent focus:bg-accent-emphasis focus:text-accent"
                >
                  <div class="flex items-center gap-2 font-medium leading-none">
                    <IconComponent name="PhRocketLaunch" class="text-accent" />
                    Create Shipment
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted">Create a new shipment</p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/address'"
                  :class="hasAccess(AccessKey.EDIT_CONFIG)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent-emphasis hover:text-accent focus:bg-accent-emphasis focus:text-accent"
                >
                  <div class="flex items-center gap-2 font-medium leading-none">
                    <IconComponent name="PhAddressBook" class="text-accent" />
                    Address Book
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted">
                    Manage your shipment addresses, for sender, receiver, and more.
                  </p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
            <li />
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/test/shipments/create'"
                  :class="hasAccess(AccessKey.EDIT_CONFIG)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent-emphasis hover:text-accent focus:bg-accent-emphasis focus:text-accent"
                >
                  <div class="flex items-center gap-2 font-medium leading-none">
                    <IconComponent name="PhFlask" class="text-attention" />
                    <span class="text-attention">Create test shipment</span>
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted">
                    To test playground settings
                  </p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
            <li>
              <NavigationMenuLink as-child>
                <RouterLink
                  :to="'/test/shipments'"
                  :class="hasAccess(AccessKey.EDIT_CONFIG)"
                  class="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent-emphasis hover:text-accent focus:bg-accent-emphasis focus:text-accent"
                >
                  <div class="flex items-center gap-2 font-medium leading-none">
                    <IconComponent name="PhFlask" class="text-attention" />
                    <span class="text-attention">Test Shipments</span>
                  </div>
                  <p class="line-clamp-2 text-sm leading-snug text-muted">
                    Overview of all test shipments
                  </p>
                </RouterLink>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenu>
</template>

<script setup lang="ts">
import { IconComponent } from '@shipitsmarter/viya-ui-warehouse';

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import { AccessKey } from '@/plugins/authorization';
import { useUserStore } from '@/store/user'; // Assuming the user store is in a separate file

// Define the hasAccess method
const hasAccess = (key: AccessKey): string => {
  const accessStore = useUserStore(); // Call the hook with parentheses

  // Check access and return appropriate string value
  return accessStore.getAccess[key] === false ? 'no-access' : '';
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}
</style>
