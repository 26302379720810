<template>
  <BaseTooltip
    v-if="(props.devMode && devModeOn) || !props.devMode"
    :content="props.label"
    side="right"
    :disabled="showLabel"
  >
    <RouterLink
      @click="$emit('router-click')"
      :style="index ? { transitionDelay: index * 30 + 'ms' } : ''"
      :to="props.link"
      active-class="!text-accent bg-gray-300"
      v-bind="$attrs"
      :class="{ 'inline-block': !showLabel, '': configMenu }"
      class="flex items-center gap-3 rounded-lg px-3 py-3 text-muted hover:bg-gray-300 hover:text-accent hover:no-underline"
    >
      <IconComponent :name="props.icon" size="xl" />
      <span v-if="showLabel" class="grow font-semibold">{{ props.label }}</span>
    </RouterLink>
  </BaseTooltip>
</template>
<script setup lang="ts">
import { IconComponent } from '@shipitsmarter/viya-ui-warehouse';

import BaseTooltip from '@/components/tooltip/BaseTooltip.vue';
import { useContextStore } from '@/store/index.ts';

interface NavSideBarButtonEmits {
  (event: 'router-click'): void;
}

defineEmits<NavSideBarButtonEmits>();

const props = defineProps<{
  link: string;
  icon: string;
  label: string;
  showLabel?: boolean;
  devMode?: boolean;
  index?: number;
  class?: string;
  configMenu?: boolean;
}>();

const store = useContextStore();
const devModeOn = store.getDevMode;
</script>
